body {
  background-color: #d1d1d1 !important;
  ;
}

.usr_stats button {
  background: green;
  border: 1px solid green;
  line-height: 1;
}

.usr_stats .disabl {
  background: red;
  border: 1px solid red;
}

.sl-spinner1 {
  position: absolute;
  background: #0000008f;
  width: 100%;
  left: 0;
  height: 100vh;
  z-index: 999999;
  top: 0;
}

.css-xb97g8:hover {
  background-color: rgb(95 202 201) !important;
  color: #fff !important;
}

.usr_dltlist {
  color: red;
  cursor: pointer;
}

.login_outer {
  height: 100vh;
  display: flex;
}
.single_line_msg {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.login_container {
  width: 25%;
  padding: 25px;
  border-radius: 5px;
  margin: auto;
  text-align: center;
  box-shadow: 0 0 10px #ccc;
  border: 1px solid #ccc;
  background-color: #fff;
}

*:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.login_form .form-control {
  border-bottom: 2px solid #ccc !important;
  padding-left: 30px !important;
  font-size: 1.1rem;
  color: #6c757d;
}

.accordion-collapse {
  width: 100%;
}

.show {
  display: inline-block;
}

.hide {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.filter-label span.sr-only {
  display: none;
}

.react-bootstrap-table td,
.react-bootstrap-table th {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: center;
}

.react-bootstrap-table th .select-filter.placeholder-selected {
  color: #000 !important;
}

.pagination {
  justify-content: end;
}

.form-control-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

/* table.table.table-bordered {
  counter-reset: serial-number;
}

table.table.table-bordered td:first-child:before {
  counter-increment: serial-number; 
  content: counter(serial-number);
} */

body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  font-family: sans-serif;
}

navbar {
  background-color: #8bc34a;
  position: absolute;
  right: 0;
  left: 0;
  height: 5em;
  display: flex;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

navbar i {
  margin: auto 0 auto 10px;
  padding: 15px;
  transition: transform 1s;
}

navbar i:hover {
  background-color: #72a03d;
  border-radius: 26px;
  transform: rotate(360deg);
}

navbar h3 {
  margin: auto;
}

aside {
  overflow-x: hidden;
  width: 0;
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  background-color: #2d2d2d;
  transition: width 0.5s ease-in-out;
}

aside ul {
  list-style: none;
  padding: 0;
}

aside ul li {
  display: flex;
  align-items: center;
  color: #d3d3d3;
  padding: 12px 0 12px 22px;
  font-weight: 400;
  transition: transform 1s;
  cursor: pointer;
}

aside ul li i {
  margin-right: 1em;
}

aside ul li:hover {
  transform: translateX(5px);
  color: #0288d1 !important;
  border-left: 5px solid #0288d1;
}

.drawerMin {
  width: 70px;
}

.drawerOpen {
  width: 240px;
}

main {
  position: absolute;
  top: 80px;
  padding: 1em;
  margin-left: 0;
  bottom: 0;
  overflow-y: auto;
  transition: margin-left 0.5s ease-in-out;
}

.mainMin {
  margin-left: 70px;
}

.mainOpen {
  margin-left: 240px;
}

.react-datetime-picker__wrapper {
  border: 0 !important;
}

.react-datetime-picker__inputGroup__input:focus-visible {
  outline: none !important;
}

.user_img img {
  width: 130px;
  display: table;
  margin: auto;
  border-radius: 50%;
  height: 130px;
}

.usrdtl_ryt {
  box-shadow: 0 0 10px grey;
  background: #fff;
  padding: 20px;
}

.top_fivuser {
  background: #fff;
}

.com_had {
  background: #fff;

  margin: 20px 0;
}

.com_had h3 {
  font-size: 16px;
  background: #6ccac9;
  color: #fff;
  padding: 10px;
}

.com_had form {
  padding: 10px 15px;
}

.top_tran {
  background: #fff;
  border-radius: 7px;
}

.flt_tr {
  padding: 31px 0;
  text-align: center;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 0;
}

.flt_tr.fs {
  background: #ff6c60;
}

.flt_tr.sn {
  background: #6ccac9;
}

.flt_tr.trd {
  background: #f8d347;
}

.flt_tr svg {
  font-size: 28px;
  color: #fff;
}

.pending {
  color: red;
  font-weight: 600;
  box-shadow: 0px 4px 10px #e6e6e6;
  display: block;
  padding: 6px;
  margin: 10px;
}

.complet {
  color: #13bb13;
  font-weight: 600;
  /* box-shadow: 0px 4px 10px #e6e6e6; */
  display: block;
  padding: 6px;
  margin: 10px;
}

.tr_tab {
  background: #fff;
}

.titl_bm {
  font-size: 20px;
  margin-bottom: 0;
  color: #fff;
}

.react-bootstrap-table-pagination-total {
  margin-left: 18px;
}

.react-bootstrap-table-pagination {
  padding: 0 12px;
}

.table.table-bordered {
  background: #fff;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #6ccac9 !important;
}

/* .react-bootstrap-table-pagination{
  background: #fff;
} */
.admin {
  color: #6ccac9;
}

.group_form {
  background: #fff;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 0 7px #80808070;
}

.view_img {
  background: #f6f6f6;
  /* height: 200px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 0 10px #d7d7d7; */
  border-radius: 3px;
}

.view_img_main {
  background: #f6f6f6;
  box-shadow: 0 0 10px #d7d7d7;
  border-radius: 3px;
  text-align: center;
  padding: 20px 0;
}

.storag {
  margin: 10px 0;
}

.storag h3 {
  font-size: 20px;
}

.view_img img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.viw_dtail {
  padding: 20px 10px;
  background: #f6f6f6;
  border-radius: 3px;
  /* height: 260px; */
}

.titl {
  background: #5fcac9;
  color: #fff;
  padding: 14px 8px;
  font-size: 22px;
  margin-bottom: 0;
}

.pln_updt {
  padding: 10px 8px;
  background: #f6f6f6;
}

.view_btn {
  background-color: #f8d347 !important;
  border-color: #f8d347 !important;
}

.table.table-bordered th:first-child {
  width: 70px;
}

.page-item.active .page-link {
  background-color: #6ccac9 !important;
  border-color: #6ccac9 !important;
}

.swal-button--danger {
  background-color: #6ccac9 !important;
}

.page-link {
  color: #000 !important;
}

.react-bootstrap-table-pagination {
  margin: 0 2px !important;
  padding: 14px 0;
  background-color: #fff;
}

.post_dv img {
  width: 100%;
}

.post_dv {
  background: #f6f6f6;
  padding: 10px;
  margin-top: 15px;
  border-radius: 4px;
}

.post_dv img {
  width: 110px;
  height: 100px;
  border: 1px solid #e1e1e1;
  padding: 4px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.post-tit {
  text-align: left;
  font-size: 20px;
}

.btnsub {
  background: #5fcac9 !important;
  color: #fff !important;
  border-color: #5fcac9 !important;
}

.tr_vw {
  border-bottom: 1px solid #dbd5d5;
  padding-bottom: 11px;
}

.pln_nm {
  font-size: 18px;
}

.bMgaAx div:first-child {
  white-space: normal !important;
}

.eUeqdG {
  white-space: normal !important;
}

.bnAwAJ {
  min-width: 50px !important;
  width: 50px !important;
}

.pro_titl {
  font-size: 18px;
  text-align: left;
  background: #b3b3b3;
  padding: 9px 10px;
  margin: 0;
  color: #fff;
}

.w-100 {
  width: 100%;
}

.pss_inc {
  font-size: 35px;
  line-height: 0;
  letter-spacing: 2px;
}

.qr_cod {
  width: 120px;
}

.prof_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.usr_nm {
  display: inline-block;
  margin-left: 15px;
}

.log_btn {
  background: #5fcac9 !important;
  border-color: #5fcac9 !important;
}

.cm_clr {
  color: #5fcac9;
}
.usr_msg {
  background: #fff;
  padding: 12px 15px 12px;
  border-radius: 15px;
  box-shadow: 0 0 10px #8080806e;
}
.usr_msg h3 {
  font-size: 20px;
  border-bottom: 1px solid #80808021;
  padding-bottom: 8px;
  margin-bottom: 18px;
  color: #5fcac9;
}
.usr_msg p{
  font-size: 14px;
}
@media (min-width:320px) and (max-width:767px) {
/* responsive */
/* .react-bootstrap-table table{
  table-layout: unset !important;
    overflow: scroll !important;
    width: 100% !important;
}
.react-bootstrap-table{
  overflow-x: scroll;
}
.header {
  inset: unset !important;
}
.content {
  inset: unset !important;
} */
.login_container{
  width: auto;
}
}
